<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                v-if="authSite"
                id="branch-site"
                ref="branch-site"
                v-model="siteSelected"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          />
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
              style="padding-top: 1.5rem"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div>
          <b-row class="m-1">
            <b-col>
              <b-table
                ref="branchDailyTable"
                responsive
                small
                show-empty
                empty-text="조회내역이 없습니다."
                thead-class="text-center"
                tbody-class="text-center"
                sort-by="baseDate"
                :sort-desc="true"
                :items="filteredItems"
                :fields="fields"
                :tbody-transition-props="transProps"
                class="main-table"
              >
                <template #thead-top>
                  <b-tr>
                    <b-th
                      rowspan="2"
                      class="align-middle"
                      style="font-size: small; min-width: 7rem;"
                    >
                      기준일자
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="align-middle"
                      style="font-size: small; min-width: 7rem;"
                    >
                      사이트
                    </b-th>

                    <b-th
                      colspan="3"
                      class="text-center align-middle"
                    >
                      입출금
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="align-middle"
                      style="font-size: small; min-width: 5rem; background-color: #21315a;"
                    >
                      정산<br>
                      <span style="font-size: xx-small;">(D=A-B-C)</span>
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="text-center align-middle"
                    >
                      배팅금
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="text-center align-middle"
                    >
                      당첨금
                    </b-th>
                  </b-tr>
                  <b-tr>

                    <b-th
                      rowspan="2"
                      class="text-center align-middle"
                      style="font-size: small; "
                    >
                      충전
                      <span style="font-size: xx-small;">(A)</span>
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="text-center align-middle"
                      style="font-size: small; "
                    >
                      환전
                      <span style="font-size: xx-small;">(B)</span>
                    </b-th>
                    <b-th
                      rowspan="2"
                      class="text-center align-middle"
                      style="font-size: small; "
                    >
                      총판출금
                      <span style="font-size: xx-small;">(C)</span>
                    </b-th>
                  </b-tr>
                </template>

                <template #cell(deposit)="data">
                  <div
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ Number(data.item.deposit).toLocaleString() }}
                  </div>
                </template>
                <template #cell(exchange)="data">
                  <div
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ Number(data.item.exchange).toLocaleString() }}
                  </div>
                </template>
                <template #cell(withdrawnAmount)="data">
                  <div
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ Number(data.item.withdrawnAmount).toLocaleString() }}
                  </div>
                </template>
                <template #cell(balance)="data">
                  <div
                    v-b-tooltip.hover.bottom.html="getBalance(data.item).formula || null"
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ getBalance(data.item).total.toLocaleString() }}
                  </div>
                </template>
                <template #cell(bettingAmount)="data">
                  <div
                    v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.casinoBettingAmount, data.item.slotBettingAmount)"
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ Number(data.item.casinoBettingAmount + data.item.slotBettingAmount).toLocaleString() }}
                  </div>
                </template>

                <!-- 당첨 금액 합산 툴팁 처리 -->
                <template #cell(winningAmount)="data">
                  <div
                    v-b-tooltip.hover.bottom.html="getTooltipContent(data.item.casinoWinning, data.item.slotWinning)"
                    class="text-right"
                    style="font-size: small; min-width: 6rem;"
                  >
                    {{ Number((data.item.casinoWinningAmount || 0) + (data.item.slotWinningAmount || 0)).toLocaleString() }}
                  </div>
                </template>

                <template
                  v-if="filteredItems.length > 0 "
                  slot="bottom-row"
                >
                  <td
                    style="background-color: #24233a; color:rgb(196,186,138)"
                    colspan="2"
                  >
                    Total
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotal('deposit')).toLocaleString() }}
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotal('exchange')).toLocaleString() }}
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotal('withdrawnAmount')).toLocaleString() }}
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotalBalance()).toLocaleString() }}
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotalBetting()).toLocaleString() }}
                  </td>
                  <td
                    class="text-right"
                    style="background-color: #24233a; color:rgb(196,186,138)"
                  >
                    {{ Number(getTotalWinning()).toLocaleString() }}
                  </td>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <!-- <div> fetchAdminBalance: {{ fetchAdminBalance }} </div> -->
        <!-- <div> flattenedItems: {{ flattenedItems }} </div> -->
      </div></b-card>
    <hr>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BTr,
  BTh,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
  BMedia,
  BAvatar,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'

import router from '@/router'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_SELECT_OPTIONS,
  FETCH_ADMIN_BALANCE,
} from '@/store/branch/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')

const branchStore = createNamespacedHelpers('branchStore')

export default {
  name: 'AdminCalculate',

  components: {
    BCard,
    BCardBody,
    BTable,
    BTr,
    BTh,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BBadge,
    BFormDatepicker,
    vSelect,
    BCardText,
    BMedia,
    BAvatar,
    BFormTextarea,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,

      // userIp: localStorage.getItem('ip'),
      branchSelected: 'admin1',

      searchFilter: null,

      flattenedItems: [],
      fields: [
        { key: 'baseDate', thClass: 'd-none ', tdClass: 'text-center' },
        { key: 'site', thClass: 'd-none ', tdClass: 'text-center' },
        {
          key: 'deposit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 입출금(출금)
        {
          key: 'exchange', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 입출금(환전)
        {
          key: 'withdrawnAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 총판 출금
        {
          key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        }, // 충환
        {
          key: 'bettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
        {
          key: 'winningAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas',
        },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },
      isExpandCollapse: false,
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.init()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...branchStore.mapGetters({
      fetchAdminBalance: 'fetchAdminBalance',
    }),

    filteredItems() {
      // let filteredData = []

      // filteredData = this.fetchAdminBalance

      // return filteredData
      return this.fetchAdminBalance
    },
  },
  watch: {
    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodFrom: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodTo: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },

    searchFilter() {
      // console.log(this.fetchRequestAlarmsBranchCashout)

      // if (this.searchFilter) {
      //   const data = this.fetchBalanceBranchDailyProfit.filter(branch => Object.values(branch).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      //   this.flattenedItems = data
      // }
      // if (this.searchFilter === '') {
      //   this.flattenedItems = this.filteredBalanceTreeByBaseDate
      // }
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.init()
  },
  methods: {
    ...branchStore.mapActions({
      $fetchAdminBalance: FETCH_ADMIN_BALANCE,
    }),
    async init() {
      this.periodFrom = moment().startOf('month').format('YYYY-MM-DD') // 이달의 첫 번째 날
      this.periodTo = moment().endOf('day').format('YYYY-MM-DD') // 오늘 날짜

      await this.fetchData()
    },
    searchData() {
      this.fetchData()
    },
    async fetchData() {
      if (!this.branchSelected) return false
      await this.$fetchAdminBalance({
        site: this.siteSelected,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      // this.flattenedItems = this.fetchAdminBalance
      // this.expandAllRows(1) // 1단계만 펼친 상태로 설정
    },

    getTotal(field) {
      return this.fetchAdminBalance.reduce((acc, item) => acc + (item[field] || 0), 0)
    },
    getBalance(data) {
      const deposit = Number(data.deposit || 0)
      const exchange = Number(data.exchange || 0)
      const withdrawn = Number(data.withdrawnAmount || 0)

      const total = deposit - exchange - withdrawn
      const showTooltip = deposit !== 0 || exchange !== 0 || withdrawn !== 0

      return {
        total,
        formula: showTooltip
          ? `${deposit.toLocaleString()} - ${exchange.toLocaleString()} - ${withdrawn.toLocaleString()}`
          : null,
      }
    },

    getTotalBalance() {
      return this.fetchAdminBalance.reduce((acc, item) => {
        const deposit = Number(item.deposit || 0)
        const exchange = Number(item.exchange || 0)
        const withdrawn = Number(item.withdrawnAmount || 0)
        const balance = deposit - exchange - withdrawn
        return acc + balance
      }, 0)
    },

    getTotalBetting() {
      return this.fetchAdminBalance.reduce(
        (acc, item) => acc + (item.sportBettingAmount || 0) + (item.casinoBettingAmount || 0) + (item.slotBettingAmount || 0),
        0,
      )
    },
    getTotalWinning() {
      return this.fetchAdminBalance.reduce(
        (acc, item) => acc + (item.sportWinningAmount || 0) + (item.casinoWinningAmount || 0) + (item.slotWinningAmount || 0),
        0,
      )
    },
    getTooltipContent(sportValue, casinoValue, slotValue) {
      const sport = Number(sportValue || 0)
      const casino = Number(casinoValue || 0)
      const slot = Number(slotValue || 0)

      // 값이 모두 0이면 툴팁 비노출
      if (sport === 0 && casino === 0 && slot === 0) return ''

      return `
          <div style="display: table;">
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">스포츠</div>
              <div style="display: table-cell; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${sport.toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">카지노</div>
              <div style="display: table-cell; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${casino.toLocaleString()}</div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell; text-align: right; padding-right: 5px;">슬롯</div>
              <div style="display: table-cell; padding-right: 5px;">:</div>
              <div style="display: table-cell; text-align: right;">${slot.toLocaleString()}</div>
            </div>
          </div>
        `
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString() // 정수로 변환 후 천 단위 쉼표 추가
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },

    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },

    // beforeRouteLeave(to, from, next) {
    //   clearInterval(this.fncShowWdrw) // 페이지 이동 시 interval 정리
    //   next()
    // },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
/* .b-table {
    overflow-y: auto;
    max-height: 300px;
}
.b-table .table > thead {
  position: sticky !important;
  top: 0;
  z-index: 2;
} */

.b-table > thead > tr > th {
  border-right: solid 1.5px #3b4253 !important;
  border-bottom: solid 1.5px #3b4253 !important;
  min-width: 6rem;
  /* white-space: no-wrap !important; */
}
.b-table > thead > tr > th:last-child {
  border-right: none !important;
}
</style>
